@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700&family=Poppins:ital,wght@0,300;1,500&display=swap');

body {
  background-color: #ffffff;
  /* background: linear-gradient(180deg, #d7dde9 0%, rgb(255, 255, 255) 100%); */
  font-family: 'Poppins ';  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: none;
}
 .Branch_section{
  background-color: #FCFAF5;
 }
/* .bg-img {
  background-image: url('./assets/bg.png');
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: -5%;
  
} */

/* .bg-img{
  position: absolute;
  top: 20;
  right: -20%;
  transform: translate(50% 100%);
  background-color: #FAECC9;
  width: 868px;
  height: 858px;
  border-top-left-radius: 80% 100%;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 20%;
  scroll-behavior: initial;
}


@font-face {
  font-family: "fontprimary";
  src: url("https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:wght@700&display=swap");
}

/* @font-face {
  font-family: "fontbold";
  src: url("/src/assets/font/CarbonaTest-SemiBold.woff");
} */
@media screen and (max-width: 768px) {
  .bg-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 600px;
    right: -20%;
  }
}

@media screen and (max-width: 576px) {
  .bg-img{
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
  }
} 

.short_description ul {
  list-style: circle !important;
}